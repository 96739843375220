.reg_reg-section {
  margin: 2em 0 0;

  table {
    table-layout: fixed;
    max-width: 550px;
    width: 100%
  }

  .input-text {
    width: 100%;
  }

  .reg_col_1 {
    font-size: 13px;
    padding: 0 1em 0 0;
    // width: 138px;
  }

  .reg_col_2 {
    padding: 0 0 0.5em;
    position: relative;
  }

  .button {
    margin: 1em 0 0;
  }

  .last {
    text-align: center;

    .button {
      display: inline-block;
    }
  }
}

.mobile,
.tablet{
  .auth-block{
    position: relative;
    float: right;
    padding: 0 0 0 0.5em;
    @include sideBorder('left');
    .auth-box{
      .reg,
      .enter,
      .profile,
      .exit{
        display: inline-block;
        width: 32px;
        height: 44px;
        a{
          display: block;
          width: 32px;
          height: 44px;
          position: relative;
          font-size: 12px;
          text-decoration: none;
          @include fontawesome(''){
            left: 0;
            font-size: 28px;
          }
        }
      }
      .reg,
      .profile{
        margin-right: 10px;
        a{
          &:before{
            content: '\f234';
            color: $white;
            margin: -22px 0 0 1px;
          }
        }
      }
      .profile{
       a{
        &:before{
          content: '\f2bd';
          color: $white;
        }
      }
    }
    .enter{
      a{
        &:before{
          content: '\f007';
          color: $white;
          margin: -22px 0 0 6px;
        }
      }
    }
    .exit{
     a{
      &:before{
        content: '\f235';
        color: $white;
        margin: -22px 0 0 0px;
      }
    }
  }
}
}
}