
.popup-block__fon {
  background: rgba(0,0,0,.5);
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  position: fixed;
}
.mobile,
.tablet{
  .popup-block {
    overflow: auto;
  }
}
.popup-block {
  position: fixed;
  background: #fff;
  box-shadow: 7px 10px 60px 0 #383838;
  border-radius: $borderRadius;
  display: none;
  font-size: 15px;
  width: 500px;
  margin: auto 0 auto -250px;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: 1010;
  max-height: 580px;
  overflow-y: auto;
  @include media(md) {
    max-height: fit-content;
  }
  .popup-block__content {
    margin: 1em 0 0;
    padding: 0 3em;
    @include media(sm) {
      padding: 0 1em;
    }
    text-align: center;
  }


  .input-text {
    width: 100%;
  }

  .input-textarea {
    height: 8em;
  }

  .popup-block__inner {
    padding: 1em 1em 2em;
    position: relative;
    @include media(sm) {
      padding: 1em 0;
    }
  }

  .form_block {
    &.check-agree {

    }

    &.form_block__last {
      margin: 0;

      .fb_title {
        font-size: 12px;
      }
    }
    .g-recaptcha{
      margin: 0 0 1em;
    }

  }

  .popup-block__title {
    font-size: 24px;
    padding: 1em 0;
  }

  .popup-block__text{
    font-size: 13px;
    text-align: left;
    padding: 0 3em;
  }


  @include media(sm) {

    .popup-block__content {
      margin: 0.5em 0 0;
    }

    .popup-block__title {
      // font-size: 14px;
    }

    .form_block {
      &.check-agree {
        & > label {
          font-size: 9px;
          line-height: 1.4;
        }
      }
    }
  }
}


.popup-block__cross {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 40px;
  will-change: transform;
  z-index: 2;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -7px 0 0 -7px;
    background: url(../images/close.png) 0 0 no-repeat;
    width: 14px;
    height: 14px;
  }

  &:hover {
    &:before {
      color: $linkColor;
      transition: color 0.3s ease;
    }
  }

}


.popup-block {

  &.reg-window {
    // margin: -96px 0 0 -224px;
    // width: 448px;
    max-height: 360px;
    .reg_auth-section {

      .addition-block {
        float: right;
        margin: 8px 0 0;
        text-align: right;

        & > a {
          display: block;
          font-size: 13px;
          line-height: 1.4;
          margin: 0 0.5em;
          -webkit-tap-highlight-color: transparent;

          & > span {
            -webkit-tap-highlight-color: $webkitHighlightTapColor;
          }
        }
      }

      .form_block {

        &.form_block__last {
          float: left;
          padding: 0.5em 0 0;
          width: 100px;

          & > .form_title {
            font-size: 11px;
            top: -16px;
          }
        }
      }
    }


    @include media(sm) {
      // width: calc(320px - 2em);
      // margin: -90px 0 0 calc(160px - 320px + 1em);
      width: 100%;
      left: 0;
      margin: 0;

      .popup-block__title {
        padding: 0 1.5em;
        margin: 0 0 0.5em;
      }

      .addition-block {
        float: right;
        margin: -5px 0 0;
        text-align: right;
        width: 120px;

        & > a {
          font-size: 13px;
          display: inline-block;
        }
      }
    }
  }
  &.add-comment{
    max-height: 140px;
  }
  &.fast_order_form{
    max-height: 370px;
  }
  &.fast_ordert_ok, &.podpiska{
    max-height: 200px;
  }
}

.restore_pass-section {
  background: $white;
  display: none;
  height: auto;
  padding: 1em 1em 2em;
  .restore_pass-form{
    padding: 0 3em;
    @include media(sm) {
      padding: 0 1em;
    }
  }
  .form_block {

    &.key-block {
      .input-text {
        float: left;
        width: calc(100% - 1em - 92px);
      }

      .key-img {
        position: absolute;
        top: -10px;
        right: 0;
        height: 34px;
        width: 92px;
      }

      .help-block {
        right: 11em;
      }
    }

    &.key-title {
      margin: 0.5em 0 0;

      .form_title {
        font-size: 11px;
        position: static;
      }
    }


    &.form_block__last {
      .button {
        margin: 1em 0 0;
      }
    }
  }
}


.popup-block {
  &.auth-ok {
    max-height: 140px;
    // margin-top: -3em;
  }
}


.popup-block {
  &.auth-error {
    max-height: 140px;
    // margin-top: -3em;
  }
}

.popup-block {
  &.order-call,
  &.p_review,
  &.fast_order_form {
    // margin-top: -172px;

    @include media(sm) {
      // max-width: calc(320px - 2em);
      // margin: -12em 0 0 calc(160px - 320px + 1em);
      width: 100%;
      left: 0;
      margin: 0;
    }
  }
}


.popup-block {
  &.sendformok {
   max-height: 150px;
 }
 .popup-block__title{
  padding: 0.5em 0;
}
}


.popup-block {
  &.callbackformok {
    margin-top: -3em;
  }
}


.popup-block {
  &.add-to-cart {
    max-height: 150px;
    // margin: -46px 0 0 -202px;
    @include media(sm) {
      width: 100%;
      // left: 0;
      margin: 0;
    }
    .link-to-cart {
      @extend button;
      position: relative;
    }

    @include media(sm) {
     margin: 30px 0 0 -145px;
     max-width: 290px;
     width: 290px;
   }

 }
}



.popup-block {
  &.compare-popup {
    top: 50%;
    left: 50%;
    height: 800px;
    margin: -400px 0 0 calc(-600px + 1em);
    width: calc(1200px - 2em);

    @include media(xlg) {
      margin: -400px 0 0;
      left: 1em;
      right: 1em;
      width: auto;
    }
  }


  &.compare-popup-error {
    width: 404px;
    margin: auto 0 auto -202px;
    max-height: 140px;
  }
}




// Окно быстрого просмотра продукта
.product-popup {
  height: 100vh;
  left: 0;
  outline: medium none;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  vertical-align: middle;
  width: 100vw;
  z-index: 1100;
  -webkit-overflow-scrolling: touch;
}


.product-popup__window {
  position: relative;
  left: 0;
  right: 0;
  top: 1em;
  bottom: 1em;
  margin: auto;
  background: #fff;
  border: 1px solid #ccc;
  max-width: $containerWidth;
  padding: 1em;
  width: 100%;
  z-index: 901;

  @include media(md) {
    top: 0;
    bottom: 0;
  }
}


.product-popup__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 2px;
  padding: 6px 6px;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;

  &:before {
    content: '\f2d3';
    display: block;
    font: normal 29px/1 "FontAwesome";
    color: $linkColor--hover;
    position: relative;
    top: 0;
  }

  &:hover {
    &:before {
      color: $linkColor;
      transition: color 0.3s ease;
    }
  }
}



#layerok {
  width: 100%;
  height: 100%;
  position:fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background: rgba(0, 0, 0, .3);
}

.g-recaptcha{
  @include media(sm){
    transform: scale(.8);
    margin: 0 0 0 -20px;
  }
  > div{
    margin: 0 auto;
  }
}
.g-recaptcha-bubble-arrow{
  + div{
    width: 100% !important;
    left: -25%;
  }
}

.p_review{
  .top{
    display: flex;
    align-items: center;
    b{
      padding: 0 1em;
    }
  }
  .bottom{
    text-align: left;
    .bottom_text{
      padding: 1em 0;
      min-height: 100px;
    }
    .bottom_date{
      font-weight: 700;
      margin: 0 0 0.5em;
    }
    .file{
      display: flex;
      align-items: center;
      margin: 0 0 1em;
      > div{
        font-size: 12px;
        padding: 0 0 0 1em;
      }
    }
  }
}