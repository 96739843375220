// Обратная связь

.feedback {

	padding: 1em;
}

.feedback-form {

	.feedback-col-6 {
		float: left;
		padding: 0 0.5em 0 0;
		width: 60%;
		@include media(xmd) {
			width: 100%;
		}
		&.last {
			padding: 0 0 0 0.5em;
			width: 40%;
			@include media(xmd) {
				width: 100%;
			}
			.feedback-block {
				margin: 0 0 1em;
			}
		}
	}

	.feedback-button-block {
		float: left;
		margin: 0;
		width: 100%;

		.form_title {
			position: static;
		}

		.form_content {
			margin: 0.5em 0 0;
		}
	}


	input {
		width: 100%;

		&.button {
			width: auto;
		}
	}

	textarea {
		resize: none;
		margin: 0 0 0.5em;
		height: 217px;
		width: 100%;
	}
}

.mobile,
.tablet{
	.feedback-form{
		@include media(xmd) {
			display: flex;
			flex-direction: column;
		}
		&:before,
		&:after{
			@include media(xmd) {
				display: none;
			}
		}
		.feedback-col-6{
			@include media(xmd) {
				order: 1;
			}
			&.last{
				@include media(xmd) {
					order: 0;
					padding: 0;
				}
			}
		}
		.feedback-button-block{
			@include media(xmd) {
				order: 2;
			}
		}
	}
}
.files_element{
	text-align: right;
	.input_file_text{
		text-align: left;
		overflow: hidden;
		line-height: 39px;
	}
	.input_file_button{
		margin: .5em 0;
	}
}