@font-face {
	font-family: 'FontAwesome';
	src: url('/assets/fonts/fontawesome/fontawesome-webfont.eot?v=4.7.0');
	src: url('/assets/fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.woff?v=4.7.0') format('woff'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
	url('/assets/fonts/fontawesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Calibri';
	src: url('/assets/fonts/Calibri/Calibri-LightItalic.eot');
	src: local('Calibri Light Italic'), local('Calibri-LightItalic'),
	url('/assets/fonts/Calibri/Calibri-LightItalic.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/Calibri/Calibri-LightItalic.woff2') format('woff2'),
	url('/assets/fonts/Calibri/Calibri-LightItalic.woff') format('woff'),
	url('/assets/fonts/Calibri/Calibri-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Calibri';
	src: url('/assets/fonts/Calibri/Calibri-Italic.eot');
	src: local('Calibri Italic'), local('Calibri-Italic'),
	url('/assets/fonts/Calibri/Calibri-Italic.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/Calibri/Calibri-Italic.woff2') format('woff2'),
	url('/assets/fonts/Calibri/Calibri-Italic.woff') format('woff'),
	url('/assets/fonts/Calibri/Calibri-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Calibri';
	src: url('/assets/fonts/Calibri/Calibri-Light.eot');
	src: local('Calibri Light'), local('Calibri-Light'),
	url('/assets/fonts/Calibri/Calibri-Light.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/Calibri/Calibri-Light.woff2') format('woff2'),
	url('/assets/fonts/Calibri/Calibri-Light.woff') format('woff'),
	url('/assets/fonts/Calibri/Calibri-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Calibri';
	src: url('/assets/fonts/Calibri/Calibri-Bold.eot');
	src: local('Calibri Bold'), local('Calibri-Bold'),
	url('/assets/fonts/Calibri/Calibri-Bold.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/Calibri/Calibri-Bold.woff2') format('woff2'),
	url('/assets/fonts/Calibri/Calibri-Bold.woff') format('woff'),
	url('/assets/fonts/Calibri/Calibri-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Calibri';
	src: url('/assets/fonts/Calibri/Calibri.eot');
	src: local('Calibri'),
	url('/assets/fonts/Calibri/Calibri.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/Calibri/Calibri.woff2') format('woff2'),
	url('/assets/fonts/Calibri/Calibri.woff') format('woff'),
	url('/assets/fonts/Calibri/Calibri.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Calibri';
	src: url('/assets/fonts/Calibri/Calibri-BoldItalic.eot');
	src: local('Calibri Bold Italic'), local('Calibri-BoldItalic'),
	url('/assets/fonts/Calibri/Calibri-BoldItalic.eot?#iefix') format('embedded-opentype'),
	url('/assets/fonts/Calibri/Calibri-BoldItalic.woff2') format('woff2'),
	url('/assets/fonts/Calibri/Calibri-BoldItalic.woff') format('woff'),
	url('/assets/fonts/Calibri/Calibri-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

