.header-cart {
	&.open {

		.ajaxflowcart-block {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition: transform 0.3s ease, opacity 0.15s ease 0.15s;
			visibility: visible;
		}
	}
}


.cart {
	display: inline-block;
	text-align: left;
	position: relative;
	-webkit-tap-highlight-color: rgba(0,0,0,0.15);


	&.empty {
		cursor: default;
	}

	&.full {
		cursor: pointer;
		.header_cart-goods-quantity,
		.count-quantity,
		.qt {
			color: $linkColor;
		}
	}
}



.desktop {
	.cart {
		&.full {
			&:hover {

				.cart-title {
					color: $linkColor;
					transition: color 0.2s ease;
				}

				&:before {
					color: $linkColor;
					transition: color 0.2s ease;
				}
			}
		}
	}
}



// Корзина /ishop/cart


.cart-table {
	margin: 0 0 1em;
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	text-align: center;
	vertical-align: middle;

	.cart-table__delete-btn {
		cursor: pointer;
		display: inline-block;
		font-style: normal;
		width: 32px;
		height: 32px;
		border-radius: $borderRadius;
		text-align: center;
		line-height: 28px;
		padding: 0;
		position: relative;
		-webkit-tap-highlight-color: $webkitHighlightTapColor;

		&:hover {
			&:before {
				color: $linkColor--hover;
				transition: color 0.2s ease;
			}
		}

		@include fontawesome('\f00d') {
			color: $linkColor;
			font-size: 21px;
			margin: -14px 0 0 -8px;
		}

	}


	.cart_product_photo img {
		height: auto;
		max-width: 100px;
		width: 100%;
		border-radius: $borderRadius;
	}

	.input-count {
		padding: 5px 9px 5px 1em;
		max-width: 76px;
		width: 100%;
		border-radius: $borderRadius;
		outline: none;
		border: 1px solid #aaa;
	}

	& > thead {
		& > tr {
			border-bottom: 1px solid $borderColor;

			& > th {
				padding: 6px 0;

				&:nth-child(1) {
					width: 13%;
				}
				&:nth-child(2) {
					width: 32%;
				}
				&:nth-child(3) {
					width: 14%;
				}
				&:nth-child(4) {
					width: 15%;
				}
				&:nth-child(5) {
					width: 16%;
				}
				&:nth-child(6) {
				}

			}
		}
	}

	& > tbody {
		& > tr {
			border-bottom: 1px solid $borderColor;
			border-radius: $borderRadius;

			& > td {
				padding: 6px 5px;
				/*&.cart_product_photo {
					width: 13%;
				}
				&.cart_product_title {
					width: 32%;
				}
				&.cart_product_price {
					width: 14%;
				}
				&.cart-table__count {
					width: 15%;
				}
				&.cart_prices {
					width: 16%;
				}
				&.cart_product_delete {
					width: 10%;
					}*/
				}
			}
		}
	}

	.cart-table__skidki {
		width: 100%;
		margin: 0 0 1em;
		.cart_summary_title,
		.cart_skidka_title {
			font-weight: 700;
			width: 74%;
		}
		.cart_big_prices,
		.cart_skidka_price {
			width: 16%;
			text-align: center;
		}
	}

	.cart-table__deliver {
		margin: 0 0 3em;
		width: 100%;
		text-align: center;
		> tbody{
			> tr:nth-child(1){
				td{
					padding-top: 3em !important;
				}
			}
		}
		.dostavka_title{
			text-align: left;
			+ .dostavka_radio{
				+ .dostavka_price{
					+ td{
						@include media(lg){
							display: none;
						}
					}
				}
			}
		}

		.dostavka_radio {

			& > label {
				padding: 0;
				height: 20px;
				width: 20px;
				display: inline-block;
			}
		}


		.radio {
			&:not(checked) {
				& + label {
					&:before {
						margin-top: -5px;
					}

					&:after {
						margin-top: -2px;
					}
				}
			}
		}

		.dostavka_header {
			vertical-align: top;
			@include media(lg){
				display: block;
			}
		}

		tr {
			border-bottom: 1px solid $borderColor;
		}

		td {
			padding: 3px 0;
		}

		tfoot {
			& > tr {
				border-bottom: none;
			}
		}
		.cart_summary_title{
			font-weight: 700;
			width: 59%;
			@include media(lg){
				width: auto;
			}
		}
		.cart_summary_counts{
			font-weight: 700;
			width: 15%;
			@include media(lg){
				width: auto;
			}
		}
		.cart_big_prices{
			font-weight: 700;
			width: 16%;
			white-space: nowrap;
			@include media(lg){
				width: 100% !important;
				display: block;
			}
		}
	}
	.cart-table__deliver{
		tbody{
			.cart_summary_title{
				font-weight: 700;
				padding: 0 0 1em;
				// font-style: italic;
			}
			#cart_summary_summa{
				font-weight: 700;
				padding: 0 0 1em;
				// font-style: italic;
			}
			.cart_big_prices{
				font-weight: 700;
			}
		}
	}

	.order_ok_table {
		table-layout: fixed;
		text-align: left;
		width: 100%;

		th,
		td {
			padding: 6px 0;
		}

		tr {
			border-bottom: 1px solid $borderColor;

			&:last-child {
				border-bottom: none;
			}
		}

	}


	#cart_form{
		max-width: $containerWidth / 1.5;
		margin: 1em auto 0;
		.my-cart-table{
			tr{
				td{
					display: block;
					padding: 0.5em 0;
				}
			}
		}
	}

	.cart_buttons_block{
		text-align: center;
		margin: 1em 0 0;
		.button{
			width: 100%;
			font-size: 24px;
			font-weight: 700;
		}
	}

	.mod_cards{
		display: flex;
		align-items: center;
		justify-content: space-between;
		// flex-wrap: wrap;
		.mod_cards_title{
			-ms-flex: 0 0 auto;
			flex: 0 0 auto;
			width: auto;
			max-width: none;
			font-size: 18px;
			font-weight: 600;
		}
		.mod_cards_input{
			width: 100%;
			padding: 0 2em;
		}
		.mod_cards_button{

		}
	}

	input[name="card_nomer"] {
		&.count_prd{
			width: 100%;
		}
	}

	.cart_prices{
		.cart_big_prices{
			font-size: 18px;
			font-weight: 600;
		}
	}

	.cart_product_title{
		font-size: 18px;
		font-weight: 600;
		.prod-param{
			font-weight: 400;
		}
	}