.podpiska_form {
	.form_content{
		padding: 0 1em;
	}
	.input-text{
		margin: 0 auto;
		padding: 3px 2em 3px 0.5em;
		height: inherit;
		width: 100%;
	}
	.form_block{
		+ div{
			text-align: center;
			padding: 1em 0;
		}
	}
}