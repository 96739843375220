/* Выбор валюты */
.currency-list {
  display: block;
  list-style: none;
  margin: 0;
  vertical-align: middle;


  & > li {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 0 6px 0 0;
    width: 36px;
    text-align: center;
    padding: 8px 0 5px;
    height: 38px;
    -webkit-tap-highlight-color: $webkitHighlightTapColor;


    /* Всплывающая подсказка */
    &:after {
      background: $linkColor--hover;
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      opacity: 0;
      font-size: 11px;
      color: #fff;
      display: block;
      padding: 0.3em 0.6em 0.4em;
      visibility: hidden;
      white-space: nowrap;
      z-index: 10;
    }

    &:not(.selected) {
      &:after {
        content: 'Выбрать';
      }
    }

    &.selected {
      color: $linkColor--hover;
      cursor: default;

      &:after {
        content: 'Текущая валюта';
      }
    }

    &:last-child {
      margin: 0;
    }

    &.rub {

      @include fontawesome('\f158') {
        color: $white;
        font-size: 24px;
        margin: -17px 0 0 -6px;
      }

      &:not(.selected) {
        &:hover {
          &:before {
            color: $linkColor--hover;
          }
        }
      }


      &.selected {
        &:before {
          color: $linkColor--hover;
        }
      }
    }


    &.dol {

      @include fontawesome('\f155') {
        color: $white;
        font-size: 21px;
        margin: -16px 0 0 -6px;
      }
      

      &:not(.selected) {
        &:hover {
          &:before {
            color: $linkColor--hover;
          }
        }
      }

      &.selected {
        &:before {
          color: $linkColor--hover;
        }
      }
    }
  }
}


.desktop {
  .currency-list {
    & > li {
      &:hover {
        &:after {
          opacity: 1;
          transition: opacity 0.3s ease 0.2s;
          visibility: visible;
        }
      }
    }
  }
}

