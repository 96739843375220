.highlight {
    background: rgba(255,215,0,0.115);
}
code {
    color: $linkColor;
    font-family: monospace;
    font-size: 16px;
    background: #f7f7f7;
    padding: 3px 5px;
    border-radius: 3px;
    border: 1px solid #ebebeb;
}
button#ihavecookiesBtn {
    margin-left: 0px !important;
}
/* Cookie Dialog */
#gdpr-cookie-message {
    position: fixed;
    right: 30px;
    bottom: 30px;
    max-width: 375px;
    background-color: $moduleHeaderBg;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 6px 6px rgba(0,0,0,0.25);
    margin-left: 30px;
    font-family: system-ui;
    z-index: 999;
    @include media(sm) {
        bottom: inherit;
        top: 60px;
        overflow-y: auto;
        padding-bottom: 70px;
        height: 100%;
        max-height: 450px;
    }
}
#gdpr-cookie-message{
    .close{
        position: absolute;
        top: 0;
        right: 10px;
        color: $white;
        font-size: 18px;
        font-weight: 700;
        cursor: default;
        &:hover{
            color: $linkColor;
        }
    }
}
#gdpr-cookie-message h4 {
    color: $linkColor;
    font-weight: 500;
}
#gdpr-cookie-message h5 {
    color: $linkColor;
    font-size: 15px;
    font-weight: 500;
}
#gdpr-cookie-message p, #gdpr-cookie-message ul {
    color: white;
    font-size: 12px;
    line-height: 1.5em;
}
#gdpr-cookie-message p:last-child {
    margin-bottom: 0;
    text-align: right;
}
#gdpr-cookie-message li {
    width: 49%;
    display: inline-block;
}
#gdpr-cookie-message a {
    color: $linkColor;
    text-decoration: none;
    font-size: 12px;
    padding-bottom: 2px;
    border-bottom: 1px dotted rgba(255,255,255,0.75);
    transition: all 0.3s ease-in;
}
#gdpr-cookie-message a:hover {
    color: white;
    border-bottom-color: $linkColor;
    transition: all 0.3s ease-in;
}
#gdpr-cookie-message button,
button#ihavecookiesBtn {
    border: none;
    background: $linkColor;
    color: white;
    font-family: 'Quicksand', sans-serif;
    font-size: 15px;
    padding: 7px;
    border-radius: 3px;
    margin-left: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    @include media(sm) {
        margin-bottom: 15px;
    }
}
#gdpr-cookie-message button:hover {
    background: white;
    color: $linkColor;
    transition: all 0.3s ease-in;
}
button#gdpr-cookie-advanced {
    background: white;
    color: $linkColor;
}
#gdpr-cookie-message button:disabled {
    opacity: 0.3;
}
#gdpr-cookie-message input[type="checkbox"] {
    float: none;
    margin-top: 0;
    margin-right: 5px;
}
#gdpr-cookie-types{
    margin: 0 0 10px;
}