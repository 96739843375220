footer {

}

/* Раскладка футера */
.footer-top {

	&.inline{
		.logo{
			float: $logoPos;
			width: $logoWidth;
			margin: $logoMargin;
			padding: $logoPadding;
			@include media(lg) {
				width: 100%;
			}
			@include media(xmd) {
				float: none;
				text-align: center;
			}
			a{
				display: block;
			}
			.logo-img{
				float: left;
				width: $logoImgwidth;
				max-width: 180px;
				height: $logoImgheight;
				max-height: 58px;
				vertical-align: middle;
				@include media(xmd) {
					display: inline-block;
					float: none;
				}
			}
		}
		.contacts{
			float: $heaContFloat;
			padding: 1em 1em 0;
			width: $heaContWidth;
			@extend .clearfix;
			@include media(lg) {
				width: 100%;
				padding: 0 1em;
			}
			.phone {
				float: left;
				width: 30.333%;
				@include media(xmd) {
					width: 100%;
				}
			}
			.mail {
				float: right;
				width: 30.333%;
				@include media(xmd) {
					width: 100%;
				}
			}
			.adres {
				float: right;
				width: 39.333%;
				@include media(xmd) {
					width: 100%;
				}
			}
		}
	}

	.contacts {
		float: left;
		padding: 0 1em;
		width: 33.333%;
		.logo{
			display: inline-block;
			margin: 0 0 0.5em;
			@extend .clearfix;
			a{
				display: inline-block;
				@extend .clearfix;
			}
			.logo-img{
				float: left;
				width: $logoImgwidth;;
				max-width: $logoImgmaxWidth;
				height: $logoImgheight;
				max-height: $logoImgmaxHeight;
				vertical-align: middle;
				@include media(md) {
					display: block;
					float: none;
					margin: 0 auto;
				}
			}
		}
		@include media(md) {
			width: 100%;
			text-align: center;
			> div{
				display: block;
			}
			.logo{
				width: 100%;
			}
			.phone{
				> ul{
					> li{
						display: inline-block;
						&:first-child{
							// margin: 0 10px 0 0;
						}
					}
				}
			}
			@include media(md) {
				.phone{
					> ul{
						> li{
							display: block;
						}
					}
				}
				.icon{
					left: 0;
				}
			}
		}
	}

	.footer-menu {
		float: left;
		padding: 0 1em;
		width: 45.666%;
		@include media(md) {
			width: 100%;
		}
	}

	.sender_block{
		float: right;
		width: 20.666%;
		@include media(md) {
			width: 100%;
		}
		&.module{
			background: none;
		}
	}
}

.mobile{
	.sender_block{
		&.module{
			margin: 0;
			padding: 0 1em;
			.form_content {
				padding: 0;
			}
		}
	}
}



.footer-bottom {

	.payments {
		float: left;
		padding: 0 1em;
		width: 25%;
	}

	.social-block {
		float: left;
		padding: 0 1em;
		width: 35%;
	}

	.counters {
		float: right;
		padding: 0 1em;
		width: 30%;
	}
}


/* Оформление */
.footer-top {
	background: $footerTopBgColor;
	padding: 1em 0;


	.footer-menu {
		margin: 1.5em 0 0;

		@extend .clearfix;


		.title {
			font-weight: bold;
			margin: 0 0 0.5em;
			text-transform: uppercase;
		}

		.ishop,
		.text {
			float: left;
			width: 50%;
			@include media(xs) {
				width: 100%;
				text-align: center;
				margin: 0 0 1em;
			}
			.item {

				& > a {
					color: $textColor;
					text-decoration: none;
					line-height: 1.666;
					&:hover{
						color: $linkColor--hover;
					}
				}

				&.selected {
					& > a {
						color: $linkColor;
						cursor: default;
						text-decoration: underline;
					}
				}

				&.more{
					& > a {
						color: $linkColor;
						text-decoration: underline;
						&:hover{
							color: $linkColor--hover;
						}
					}
				}
			}
		}
	}


	.adres {
		margin: 0 0 0.5em;
		position: relative;
		padding: 0 1em 0 2em;

		.icon {
			left: 4px;
			@include fontawesome('\f041') {
				color: $iconColor;
			}
		}
	}


	.phone {
		margin: 0 0 0.5em;
		padding: 0 1em 0 2em;
		position: relative;

		.icon {
			vertical-align: top;

			@include fontawesome('\f095') {
				color: $iconColor;
			}
		}

		& > ul {
			display: inline-block;
			list-style: none;

			& > li {
				font-size: 15px;
				font-weight: bold;

				.pref {
					font-weight: normal;
				}
			}
		}


	}

	.mail {
		margin: 0 0 0.5em;
		position: relative;
		padding: 0 1em 0 2em;
		a{
			color: $linkColor;
			&:hover{
				color: $linkColor--hover;
			}
		}
		.icon {
			@include fontawesome('\f0e0') {
				color: $iconColor;
				margin: -12px 0 0 -10px;
			}
		}
	}
}


.footer-bottom {
	background: $footerBottomBgColor;
	padding: 0.25em 0;
	@include media(xmd) {
		.container{
			text-align: center;
			> div{
				width: 100%;
			}
		}
	}

	.payments {
		margin: 7px 0 0;

		& > img {
			max-width: 290px;
			width: 100%;
		}
	}

	.social-block {
		margin: 0.5em 0;
	}

	.social-block__item {
		text-align: center;
		border-radius: 50%;
		cursor: pointer;
		display: inline-block;
		height: 25px;
		width: 25px;
		margin: 0 0.5em 0 0;
		background: $white;

		&:hover{
			background: $linkColor--hover;
			& > a:before {
				color: $white
			}
		}

		& > a {
			height: 25px;
			width: 25px;
			display: inline-block;
			position: relative;
			line-height: 25px;
			text-decoration: none;
			vertical-align: middle;


			@include fontawesome('') {
				color: $linkColor;
				font-weight: 300;
				font-size: 1em;
				margin: -12px auto 0;
				left: 0;
				right: 0;
			}

			&.si1:before {
				content: '\f189';
			}
			&.si2:before {
				content: '\f263';
			}
			&.si3:before {
				content: '\f09a';
			}
			&.si4:before {
				content: '\f099';
			}
			&.si5:before {
				content: '\f1a0';
			}
			&.si6:before {
				content: '\f1fa';
			}
			&.si7:before {
				content: '\f167';
			}
			&.si8:before {
				content: '\f16d';
			}
		}
	}

	.counters {
		margin: 0.25em 0 0;
		text-align: right;
		@include media(md) {
			text-align: center;
		}

		& > a {
			display: inline-block;
			vertical-align: middle;
		}

		& > .citron-logo__link {
			display: inline-block;
			max-width: 92px;
			width: 100%;
			position: relative;
			vertical-align: middle;

			&:hover {
				.citron-logo--hover {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.citron-logo--hover {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition: opacity 0.3s ease;
			visibility: hidden;
		}
	}
}

.map{
	float: left;
	width: 10%;
	margin: 0.5em 0;
	padding: 0 1em;
	@include media(xmd){
		width: 100%;
	}
}
.copyright{
	float: left;
	margin: 0.5em 0;
	padding: 0 1em;
	width: 100%;
	@include media(xmd){
	}
}